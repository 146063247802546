const collectionQuery = `
query ($handle: String!) {
  collectionByHandle(handle: $handle) {
    id
    handle
  }
}
`;

const getCollectionId = async ({
  fetcher,
  collectionHandle,
}) => {
  try {
    const response = await fetcher({
        query: collectionQuery,
        variables: {
          handle: collectionHandle
        }
      })
      if (response?.data?.collectionByHandle?.id) {
        const collectionId = response?.data?.collectionByHandle?.id.replace('gid://shopify/Collection/', '');
        return collectionId;
      }
  } catch (error) {
    console.error('Error fetching collection ID from handle: ', collectionHandle);
  }
  return false;
};

export default getCollectionId;
