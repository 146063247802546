import { svg } from 'lit';

export default {
  cart: svg`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" alt="Shopping Cart" class="cart-svg">
      <path d="M10,-9.23705556e-14 C12.4619435,-9.23705556e-14 14.4847685,1.95443764 14.595248,4.39008602 L14.6,4.6 L14.5998527,5.399 L17.2,5.4 C17.6655292,5.4 18.063811,5.71998371 18.1715306,6.1628128 L18.1922779,6.27596527 L19.9922779,20.6759653 C20.0668845,21.2728184 19.601498,21.8 19,21.8 L19,21.8 L1,21.8 C0.39850201,21.8 -0.0668845202,21.2728184 0.00772212329,20.6759653 L0.00772212329,20.6759653 L1.80772212,6.27596527 C1.87027573,5.77553644 2.29567673,5.4 2.8,5.4 L2.8,5.4 L5.39985267,5.399 L5.4,4.6 C5.4,2.06771525 7.46771525,-9.23705556e-14 10,-9.23705556e-14 Z M16.316,7.4 L14.5998527,7.399 L14.6,9.1 C14.6,9.65228475 14.1522847,10.1 13.6,10.1 C13.0871642,10.1 12.6644928,9.71395981 12.6067277,9.21662113 L12.6,9.1 L12.5998527,7.399 L7.39985267,7.399 L7.4,9.1 C7.4,9.65228475 6.95228475,10.1 6.4,10.1 C5.88716416,10.1 5.46449284,9.71395981 5.40672773,9.21662113 L5.4,9.1 L5.39985267,7.399 L3.683,7.4 L2.133,19.8 L17.866,19.8 L16.316,7.4 Z M10,2 C8.62719687,2 7.49055361,3.08384315 7.40515028,4.43644936 L7.4,4.6 L7.39985267,5.399 L12.5998527,5.399 L12.6,4.6 C12.6,3.17228475 11.4277153,2 10,2 Z"/>
      </svg>`,
  chevronRight: svg`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="512px" height="512px" viewBox="0 0 512 512" style="color: inherit;" xml:space="preserve">
    <path d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7
    c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8
    L298.3,256z"/>
  </svg>
  `,
  chevronLeft: svg`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9
	c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8
	L213.7,256z"/>
</svg>
  `,
  plus: svg`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<polygon points="448,224 288,224 288,64 224,64 224,224 64,224 64,288 224,288 224,448 288,448 288,288 448,288 "/>
</svg>
  `,
  minus: svg`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<rect x="64" y="224" width="384" height="64"/>
</svg>
  `,
  close: svg`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4
 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1
 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1
 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/>
</svg>
  `,
  add: svg`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="512px" height="512px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
<g id="Icon_7_">
	<g>
		<path d="M416,277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416V277.333z"/>
	</g>
</g>
</svg>
  `,
  trash: svg`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<path d="M413.7,133.4c-2.4-9-4-14-4-14c-2.6-9.3-9.2-9.3-19-10.9l-53.1-6.7c-6.6-1.1-6.6-1.1-9.2-6.8c-8.7-19.6-11.4-31-20.9-31
		h-103c-9.5,0-12.1,11.4-20.8,31.1c-2.6,5.6-2.6,5.6-9.2,6.8l-53.2,6.7c-9.7,1.6-16.7,2.5-19.3,11.8c0,0-1.2,4.1-3.7,13
		c-3.2,11.9-4.5,10.6,6.5,10.6h302.4C418.2,144.1,417,145.3,413.7,133.4z"/>
	<path d="M379.4,176H132.6c-16.6,0-17.4,2.2-16.4,14.7l18.7,242.6c1.6,12.3,2.8,14.8,17.5,14.8h207.2c14.7,0,15.9-2.5,17.5-14.8
		l18.7-242.6C396.8,178.1,396,176,379.4,176z"/>
</g>
</svg>
  `,
};
