import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { merge } from 'lodash-es';
import '@shopify/buy-button-js';
// eslint-disable-next-line import/no-extraneous-dependencies

import icons from './icons.js';
import getCollectionId from './getCollectionID.js';
import createAndFetchCustomerAccessToken from './createAndFetchCustomerAccessToken.js';
import associateCustomerWithCheckout from './associateCustomerWithCheckout.js';

const { chevronRight, chevronLeft } = icons;
const OFFSET = -20;
const SHOW_PRODUCT_MODAL = false;

export class BuyButtonComponent extends LitElement {
  static styles = css`
    :host {
      display: block;
      color: var(--buy-button-component-text-color, #ffffff);
      padding: 25px;
    }
    .carousel-wrapper {
      width: 100%;
      max-width: min(var(--buy-button-component-max-width, 1420px), 100%);
      height: 500px;
      position: relative;
      margin: auto;
    }
    .carousel-left,
    .carousel-right {
      position: absolute;
    }
    .carousel-right,
    .carousel-left {
      position: absolute;
      top: 132px;
      z-index: 1;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      font-size: 23px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      border: var(--buy-button-component-carousel-actions-border, thin solid white);
      padding: 0;
      fill: currentColor;
      margin: auto;
      cursor: pointer;
      background-color: var(
        --carousel-actions-background,
        rgba(var(--light-color), 0.5)
      );
      color: var(--buy-button-component-carousel-actions-color, rgb(var(--highlight-color, 255,255,255)));
    }
    .carousel-right:hover,
    .carousel-left:hover,
    .carousel-right:focus,
    .carousel-left:focus {
      border: 2px solid
        var(--buy-button-component-carousel-actions-hover-border-color, rgba(var(--highlight-color, 255,255,255), 1));
      color: var(
        --buy-button-component-carousel-actions-hover-color,
        rgba(var(--highlight-color), 1)
      );
      opacity: 1;
    }
    .carousel-left {
      left: -90px;
    }
    .carousel-left svg {
      padding-right: 3px;
    }
    .carousel-right {
      right: -90px;
    }
    .carousel-right svg {
      padding-left: 3px;
    }
    .carousel-left svg,
    .carousel-right svg {
      height: 23px;
    }
    @media (max-width: 1620px) {
      .carousel-left {
        left: -20px;
      }
      .carousel-right {
        right: -20px;
      }
    }
    @media (max-width: 600px) {
      :host {
        padding: 0px;
      }
      .carousel-right,
      .carousel-left {
        border: var(--buy-button-component-carousel-actions-border-mobile, none);
      }
      .carousel-left {
        left: 0px;
      }
      .carousel-right {
        right: 0px;
      }
      .carousel-right:hover,
      .carousel-left:hover,
      .carousel-right:focus,
      .carousel-left:focus {
        border: 2px solid
          var(--buy-button-component-carousel-actions-hover-border-color-mobile, transparent);
        color: var(
          --buy-button-component-carousel-actions-hover-color-mobile,
          rgba(var(--highlight-color), 1)
        );
        opacity: 1;
      }
    }
  `;

  static properties = {
    header: { type: String },
    counter: { type: Number },
    idx: { type: String },
    domain: { type: String },
    storefrontAccessToken: { type: String },
    collection: { type: String },
    collectionHandle: { type: String },
    cssVars: { type: Object },
    customCSS: { type: String },
    overrideConfig: { type: Object },
    analytics: { type: Object },
    checkoutUTMParams: { type: Object },
    productUTMParamsOverride: { type: Object },
    moneyFormat: { type: String },
    productPath: { type: String },
    disableCart: { type: Boolean },
    multipassToken: { type: String },
  };

  constructor() {
    super();
    this.idx = this.idx || 'carousel';
  }

  getRefById = id => {
    const ref = this.shadowRoot?.getElementById(id);
    if (ref) return ref;
    return null;
  };

  appendStyleSheetWithGlobalVars = (elToAppendTo) => {
    if (this.cssVars) {
      const style = document.createElement('style')
      const textContent = `
      :root {
        ${Object.entries(this.cssVars).reduce((a, n) => `${a}${n[0]}: ${n[1]};\n`, '')}
      }`
      style.textContent = textContent;
      elToAppendTo.appendChild(style)
    }
    if (this.customCSS) {
      const style = document.createElement('style')
      const textContent = this.customCSS;
      style.textContent = textContent;
      elToAppendTo.appendChild(style)
    }
  }

  getConfig = (collection, nodeRef, ui) => {
    let productPath;
    let productImageWrapperTemplate;
    const utmParms = {
      ...(this.checkoutUTMParams || {}),
      ...(this.productUTMParamsOverride || {}),
    }
    const urlParams = Object.entries(utmParms).reduce((acc, [paramKey, paramVal]) => {
      let paramPrefix = '?';
      if (acc.includes(paramPrefix)) {
        paramPrefix = '&'
      }
      if (!paramVal) return acc;
      return `${acc}${paramPrefix}${paramKey}=${paramVal}`
    }, '')
    if (this.productPath) {
      if (this.productPath.includes('https://')) {
        productPath = `${this.productPath}`;
      } else {
        // eslint-disable-next-line no-undef
        productPath = `https://${this.domain || __SHOPIFY_DOMAIN__}${this.productPath}`
      }
      productImageWrapperTemplate = {
        img: `
        {{#data.currentImage.srcLarge}}
          <a
            type="button"
            tabindex="0"
            href="${productPath}{{data.handle}}${urlParams}"
            aria-label="Click to view product: {{data.title}}"
            target="_blank"
            class="{{data.classes.product.imgWrapper}}"
            data-element="product.imgWrapper"
          >
            <img
              alt="{{data.currentImage.altText}}"
              data-element="product.img"
              class="{{data.classes.product.img}}"
              src="{{data.currentImage.srcLarge}}"
            />
          </a>
        {{/data.currentImage.srcLarge}}`
      }
    }

    const trackProduct = (e) => {
      try {
        const products = this.getRefById(this.idx).querySelector('iframe').contentWindow.document.body.querySelectorAll('.shopify-buy__product')
        let index;
        products.forEach((product, i) => {
          if (product.contains(e.target)) {
            index = i;
          }
        });
        const currentProduct = ui.components.collection[0].products[index];
        const currentProductHandle = currentProduct.model.handle;
        let path;
        if (this.productPath.includes('https://')) {
          path = `${this.productPath}${currentProductHandle}`;
        } else {
          // eslint-disable-next-line no-undef
          path = `https://${this.domain || __SHOPIFY_DOMAIN__}${this.productPath}${currentProductHandle}`
        }
        ui.tracker.lib.track('Product Viewed', {
          id: currentProduct.model.id,
          name: currentProduct.model.title,
          productId: currentProduct.model.id,
          productName: currentProduct.model.title,
          url: path,
          carousel_index: index,
        });
      } catch (error) {
        console.error('error tracking product click: ', error);
      }
      return true;
    }

    const defaultConfig = {
      id: collection,
      node: nodeRef,
      moneyFormat: this.moneyFormat ? encodeURIComponent(this.moneyFormat) : '%24%7B%7Bamount%7D%7D',
      options: {
        product: {
          templates: {
            ...(productImageWrapperTemplate || {})
          },
          styles: {
            product: {
              'min-width': 'var(--buy-button-component-product-card-width, 268px)',
              'max-width': 'var(--buy-button-component-product-card-width, 268px)',
              width: 'var(--buy-button-component-product-card-width, 268px)',
              '@media (max-width: 600px)': {
                'min-width': '100%!important',
                'max-width': '100%!important',
                width: '100%!important',
              },
              'margin-left': '20px',
              'margin-bottom': '12.5px',
              'margin-top': '0px!important',
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              'flex-wrap': 'wrap',
              background: 'var(--buy-button-component-card-background, #1C1e28)',
              ':first-child': {
                'margin-left': '0px',
              },
              'font-family':
                'var(--buy-button-component-text-font-family, "Helvetica")',
              img: {
                width: 'calc(100% - 60px)',
                '@media (max-width: 600px)': {
                  width: '100%!important',
                  height: '90%',
                  'object-fit': 'contain',
                },
                margin: '0 auto',
              },
              imgWrapper: {
                width: '100%',
                height: '268px',
                background: 'var(--buy-button-component-image-wrapper-background, #232633)',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
              },
              title: {
                height: '50px',
                width: '100%',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                padding: '0 8px',
                overflow: 'visible',
                color: 'var(--buy-button-component-text-color, #FFFFFF)',
                'margin-top': '16px',
                'margin-bottom': '8px',
                'font-size': '1.32em',
                'font-family':
                  'var(--buy-button-component-header-font-family, "Times New Roman")',
              },
              compareAt: {
                // display: 'none',
                color: 'var(--buy-button-component-text-color, #FFFFFF)',
                'font-size': '1.15em',
                'padding-left': '3px',
              },
              prices: {
                width: 'fit-content',
                padding: '0 16px',
                'margin-bottom': '0px',
              },
              price: {
                color: 'var(--buy-button-component-text-color, #FFFFFF)',
                'font-size': '1.15em',
                'font-family':
                  'var(--buy-button-component-price-font-family, var(--buy-button-component-text-font-family, "Times New Roman"))',
              },
              options: {
                width: '96px',
                'padding-left': '16px',
                'border-left':
                  'thin solid var(--buy-button-component-border-accent-color, #6A6B78)',
              },
              buttonWrapper: {
                margin: '14px auto 0px',
                width: '100%',
                'padding-bottom': '36px',
              },
            },
            button: {
              ':hover': {
                'background-color': 'var(--buy-button-component-button-hover-background-color, #494383)',
                color: 'var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff))',
              },
              'background-color': 'var(--buy-button-component-button-background-color, #514a92)',
              ':focus': {
                'background-color': 'var(--buy-button-component-button-hover-background-color, #494383)',
                color: 'var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff))',
              },
              'border-radius': '30px',
              color: 'var(--buy-button-component-button-color, var(--buy-button-component-text-color, #ffffff))',
              height: 'fit-content',
              padding: '8px 22px',
              'text-transform': 'uppercase',
              'font-weight': 'bold',
              'font-family':
                'var(--buy-button-component-button-font-family, "Helvetica")',
              'text-decoration': 'none'
            },
          },
          text: {
            button: 'Add to cart',
            outOfStock: 'Sold Out'
          },
          buttonDestination: that => {
            if (SHOW_PRODUCT_MODAL && that.model.variants?.length > 1) {
              that.openModal();
            } else {
              that.props.closeModal();
              that._userEvent('addVariantToCart');
              that.props.tracker.trackMethod(
                that.cart.addVariantToCart.bind(that),
                'Update Cart',
                that.selectedVariantTrackingInfo,
              )(that.selectedVariant, that.selectedQuantity);
            }
          },
          DOMEvents: { 
            ...(this.productPath ? {
              [`click .shopify-buy__product-img-wrapper`]: trackProduct
            } : {}),
            ...(this.disableCart ? {
              [`click a.shopify-buy__btn`]: trackProduct
            } : {})
          },
        },
        productSet: {
          // iframe: false,
          contents: {
            products: true,
            pagination: false,
          },
          styles: {
            products: {
              '@media (min-width: 601px)': {
                'margin-left': '-20px',
              },
              display: 'flex',
              'flex-wrap': 'nowrap',
              '-webkit-flex-wrap': 'nowrap',
              'overflow-x': 'auto',
              '-webkit-overflow-scrolling': 'touch',
              'justify-content': 'initial',
              '-webkit-justify-content': 'initial',
              'margin-left': '0',
              '::-webkit-scrollbar': {
                '-webkit-appearance': 'none',
                height: '8px',
              },
              '::-webkit-scrollbar-thumb': {
                'border-radius': '8px',
                border:
                  '1px solid var(--buy-button-component-scroller-color, #232633)',
                'background-color':
                  'var(--buy-button-component-scroller-color, #232633)',
              },
            },
          },
          events: {
            afterRender: () => {
              const iframes = document.querySelectorAll('.shopify-buy-frame iframe');
              const iframeInComponent = this.getRefById(this.idx).querySelector('iframe');
              [iframeInComponent, ...iframes].forEach(iframe => {
                this.appendStyleSheetWithGlobalVars(iframe.contentWindow.document.body)
              })
              setTimeout(() => {
                this.resizeListener();
                const containerRef = this.getContainerRef();
                containerRef?.addEventListener('scroll', this.scrollListener);
                window?.addEventListener('resize', this.resizeListener);
                // if (ui.components.cart[0].model.webUrl) {
                //   console.log('ui.components.cart[0].model.webUrl: ', ui.components.cart[0].model.webUrl);
                // }
                this.handlePrices();
              }, 0);
            },
          },
        },
        modalProduct: {
          contents: {
            img: false,
            imgWithCarousel: true,
            button: false,
            buttonWithQuantity: true,
          },
          styles: {
            product: {
              '@media (min-width: 550px)': {
                'max-width': '100%',
                'margin-left': '0px',
                'margin-bottom': '0px',
              },
            },
            button: {
              ':hover': {
                'background-color': 'var(--buy-button-component-button-hover-background-color, #494383)',
                color: 'var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff))',
              },
              'background-color': 'var(--buy-button-component-button-background-color, #514a92)',
              ':focus': {
                'background-color': 'var(--buy-button-component-button-hover-background-color, #494383)',
                color: 'var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff))',
              },
              'border-radius': '30px',
              'font-family':
                'var(--buy-button-component-button-font-family, "Helvetica")',
            },
          },
          text: {
            button: 'Add to cart',
          },
        },
        option: {
          styles: {
            select: {
              background: 'var(--buy-button-component-card-background, #1C1e28)',
              color: 'var(--buy-button-component-text-color, #FFFFFF)',
            },
            wrapper: {
              border:
                'thin solid var(--buy-button-component-border-accent-color, #6A6B78)',
            },
          },
        },
        lineItem: {
          styles: {
            image: {
              top: '20px',
            },
            lineItem: {
              'border-top': 'thin solid var(--buy-button-component-border-accent-color, #6A6B78)',
              'padding-top': '20px',
              'padding-bottom': '10px',
              ':first-child': {
                'border-top': 'none',
              }
            },
            itemTitle: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              'font-family':
                'var(--buy-button-component-header-font-family, "Times New Roman")',
              'font-size': '1.2em',
              'margin-bottom': '4px',
            },
            variantTitle: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              'margin-top': '0px',
              'margin-bottom': '0px',
              'line-height': '2.4',
            },
            price: {
              float: 'left',
              'text-align': 'left',
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              'font-family':
                  'var(--buy-button-component-price-font-family, var(--buy-button-component-text-font-family, "Times New Roman"))',
            },
            priceWithDiscounts: {
              'margin-top': '0px',
              'margin-bottom': '8px',
              float: 'left',
              width: '100%',
              'margin-left': '80px',
              'text-align': 'left',
              color: 'var(--buy-button-component-text-accent-color, #6A6B78)',
            },
            quantity: {
              width: 'max-content',
            },
            quantityDecrement: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              'border-radius': '4px',
            },
            quantityInput: {
              border: 'thin solid var(--buy-button-component-border-accent-color, #6A6B78)',
              'border-radius': '4px',
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              'margin': '0 5px',
            },
            quantityIncrement: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              'border-radius': '4px',
            },
            discount: {
              color: 'var(--buy-button-component-text-accent-color, #6A6B78)',
              width: '200%',
              'margin-top': '5px',
            }
          },
        },
        cart: {
          events: {
            openCheckout: (cart) => {
              cart.close();
            }
          },
          checkout: this.checkoutUTMParams,
          styles: {
            button: {
              ':hover': {
                'background-color': 'var(--buy-button-component-cart-button-hover-background-color, var(--buy-button-component-button-hover-background-color, #494383))',
                color: 'var(--buy-button-component-cart-button-color-hover, var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff)))',
              },
              'background-color': 'var(--buy-button-component-cart-button-background-color, var(--buy-button-component-button-background-color, #514a92))',
              ':focus': {
                'background-color': 'var(--buy-button-component-cart-button-hover-background-color, var(--buy-button-component-button-hover-background-color, #494383))',
                color: 'var(--buy-button-component-cart-button-color-hover, var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff)))',
              },
              color: 'var(--buy-button-component-cart-button-color, var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff)))',
              'border-radius': '30px',
              width: '60%',
              'margin-left': '10%',
              'margin-top': '0px',
              height: '48px',
              'font-weight': 'bold',
              'font-family':
                'var(--buy-button-component-button-font-family, "Helvetica")',
            },
            header: {
              background: 'var(--buy-button-component-cart-header-background, #1C1e28)',
            },
            cartScroll: {
              background: 'var(--buy-button-component-cart-body-background, #232633)',
            },
            footer: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              background: 'var(--buy-button-component-cart-footer-background, #1C1e28)',
              display: 'flex',
              'flex-flow': 'column wrap',
              height: '100px',
              'justify-content': 'flex-end',
              'align-items': 'left',
            },
            discount: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              position: 'absolute',
              top: '10px',
            },
            title: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              'font-family':
                'var(--buy-button-component-header-font-family, "Times New Roman")',
            },
            close: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
            },
            subtotalText: {
              'text-transform': 'none',
              color: 'var(--buy-button-component-text-accent-color, #6A6B78)',
              'font-size': '0.9em',
              'margin-bottom': '4px',
            },
            subtotal: {
              'font-size': '1.5em',
            },
            notice: {
              color: 'var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff))',
              display: 'none',
            },
          },
          text: {
            title: 'Your Cart',
            total: 'Total',
            button: 'CHECKOUT',
          },
          popup: false,
        },
        toggle: {
          DOMEvents: {
            [`click `]: () => {
              try {
                const products = ui.components.cart?.[0]?.lineItemCache.map(product => ({
                  id: product.variant.id,
                  name: product.title,
                  pageurl: window.location.href,
                  price: product.variant.priceV2.amount,
                  productId: product.variant.product.id,
                  quantity: product.quantity,
                  referrer: window.location.href,
                  sku: product.variant.sku,
                  subdomain: ui.client.config.domain,
                  variantName: product.variant.title,
                }))
                if (ui.tracker.lib?.track) {
                  ui.tracker.lib.track('Cart Viewed', { products })
                }
              } catch (error) {
                console.error('error logging cart viewed: ', error);
              }
              return true;
            }
          },
          styles: {
            toggle: {
              color: 'var(--buy-button-component-toggle-button-color, var(--buy-button-component-button-color,#ffffff))',
              'background-color': 'var(--buy-button-component-toggle-button-background-color, var(--buy-button-component-button-background-color, #514a92))',
              ':hover': {
                'background-color': 'var(--buy-button-component-button-hover-background-color, #494383)',
                color: 'var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff))',
              },
              ':focus': {
                'background-color': 'var(--buy-button-component-button-hover-background-color, #494383)',
                color: 'var(--buy-button-component-button-color-hover, var(--buy-button-component-text-color, #ffffff))',
              },
              'font-family':
                'var(--buy-button-component-button-font-family, "Helvetica")',
            },
          },
        },
      },
    }

    let output = defaultConfig;
    if (this.overrideConfig) {
      output = merge(defaultConfig, this.overrideConfig);
    }
    if (this.disableCart) {
      const buttonTemplate = `<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
        <a class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button" href="${productPath}{{data.handle}}${urlParams}" target="_blank">
          {{data.buttonText}}
        </a>
      </div>`
      if (output?.options?.product?.templates) {
        output.options.product.templates = {
          ...(output.options.product.templates || {}),
          button: buttonTemplate,
        };
      }
      output.options.product.buttonDestination = () => {}
    }
    return output;
  }

  async firstUpdated() {
    this.appendStyleSheetWithGlobalVars(this)
    const { ShopifyBuy } = window;
    const client = ShopifyBuy.buildClient({
      domain: this.domain || __SHOPIFY_DOMAIN__, // eslint-disable-line
      storefrontAccessToken: this.storefrontAccessToken || __SHOPIFY_STOREFRONT_ACCESS_TOKEN__, // eslint-disable-line
    });
    const ui = ShopifyBuy.UI.init(client, { tracker: this.analytics || window.analytics});
    // console.log('ui: ', ui);
    // window.ui = ui;
    let { collection } = this;
    const { fetcher } = ui.client.graphQLClient;
    if (this.collectionHandle) {
      collection = await getCollectionId({
        fetcher,
        collectionHandle: this.collectionHandle
      })
    }
    const nodeRef = this.getRefById(this.idx);
    ui.createComponent('collection', this.getConfig(collection, nodeRef, ui));

    if (this.multipassToken) {
      const customerAccessToken = await createAndFetchCustomerAccessToken({
        fetcher,
        multipassToken: this.multipassToken,
      })
      if (customerAccessToken) {
        setTimeout(async () => {
          const cart = ui.components.cart[0];
          const checkoutId = await cart.viewData.id;
            // const checkoutUpdated =
            await associateCustomerWithCheckout({
              fetcher,
              checkoutId,
              customerAccessToken
            })
        }, 1500);
      }
    }
    // this.addEventListener('update-buy-button-component-tracker', (event) => {
    //   if (event.detail) {
    //     console.log('new tracker attached to buy-component: ', event.detail);
    //     ui.tracker.lib = event.detail;
    //   } else {
    //     console.log('failed to attach new tracker to buy-component. did you forget to add your tracker to the detail of your event?: ', event);
    //   }
    // });
  }

  handlePrices() {
    const addPillToDiscountedProduct = (productCard) => {
      const actualPriceEl = productCard.querySelector(
        '.shopify-buy__product__actual-price.shopify-buy__price--lowered',
      );

      const compareAtPriceEl = productCard.querySelector(
        '.shopify-buy__product__compare-price',
      );
      const actualPrice = parseFloat(actualPriceEl?.innerHTML?.slice(1));
      const compareAtPrice = parseFloat(compareAtPriceEl?.innerHTML?.slice(1));
      if (
        actualPriceEl?.innerHTML &&
        compareAtPriceEl?.innerHTML
      ) {
        if (actualPrice >= compareAtPrice) {
          compareAtPriceEl.style.display = 'none';
        } else {
          const imageContainer = productCard.querySelector('.shopify-buy__product-img-wrapper');
          imageContainer.style.position = 'relative';
          const pill = document.createElement('span');
          pill.innerHTML = 'ON SALE';
          pill.style.padding = '5px 10px';
          pill.style.backgroundColor = 'var(--buy-button-component-button-hover-background-color, #494383)'
          pill.style.color = 'var(--buy-button-component-cart-button-color, var(--buy-button-component-cart-text-color, var(--buy-button-component-text-color, #ffffff)))'
          pill.style.position = 'absolute';
          pill.style.top = '10px';
          pill.style.right = '10px';
          pill.style.borderRadius = '3px';
          pill.style.fontSize = '0.9em';
          pill.style.fontWeight = 'bold';
          pill.style.fontFamily = 'var(--buy-button-component-button-font-family, var(--buy-button-component-button-font-family, "Helvetica"))';
          imageContainer.appendChild(pill);
        }
      }
    }
    const containerRef = this.getContainerRef();
    const productCards = Array.from(containerRef.childNodes);
    productCards.forEach(productCard => {
      addPillToDiscountedProduct(productCard);
      const multiVariantProductSelectors = productCard.querySelectorAll('.shopify-buy__product__variant-selectors')
      if (multiVariantProductSelectors.length) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (!mutation.addedNodes) return;
            addPillToDiscountedProduct(productCard);
          });
        });
        multiVariantProductSelectors.forEach(selector => {
          observer.observe(selector, {
            // childList: true,
            subtree: true,
            attributes: true,
            // characterData: false,
          });
        })
      }
    });
  }


  scrollCarouselLeft() {
    const containerRef = this.getContainerRef();
    if (
      containerRef &&
      this.currentLeft // && +(this.currentLeft?.id || 0) >= 0
    ) {
      // const leftIndex = +(this?.currentLeft?.id || 0);
      // const rightIndex = +(this?.currentRight?.id || 0);
      // const distance = leftIndex === 0 ? 0 : rightIndex - leftIndex;
      // if (distance) {
      //   this.track({
      //     id: 'Embed Carousel Left Click',
      //     params: {
      //       products: this.products.slice(
      //         leftIndex - distance,
      //         rightIndex - distance
      //       ),
      //     },
      //   });
      // }
      containerRef.scrollTo({
        left:
          containerRef.scrollLeft -
          (this.containerRight -
            (this.currentLeft?.getBoundingClientRect().left || 0)) +
          OFFSET,
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  scrollCarouselRight() {
    const containerRef = this.getContainerRef();
    if (
      containerRef &&
      this.currentRight // &&
      // +(this.currentRight?.id || 0) <= this.products.length - 1
    ) {
      // const leftIndex = +(this?.currentLeft?.id || 0);
      // const rightIndex = +(this?.currentRight?.id || 0);
      // const distance =
      //   rightIndex === this.products.length - 1 ? 0 : rightIndex - leftIndex;
      // if (distance) {
      //   this.track({
      //     id: 'Embed Carousel Right Click',
      //     params: {
      //       products: this.products.slice(
      //         leftIndex + distance,
      //         rightIndex + distance
      //       ),
      //     },
      //   });
      // }
      containerRef.scrollTo({
        left:
          containerRef.scrollLeft -
          this.containerLeft +
          this.currentRight.getBoundingClientRect().left,
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  getContainerRef = () =>
    this.getRefById(this.idx)
      .querySelector('iframe')
      .contentWindow.document.body.querySelector(
        '.shopify-buy__collection-products',
      );

  resizeListener = () => {
    const containerRef = this.getContainerRef();
    if (containerRef) {
      this.containerRight = containerRef?.getBoundingClientRect().right || 0;
      this.containerLeft = containerRef?.getBoundingClientRect().left || 0;
    }
    this.scrollListener();
  };

  scrollListener = () => {
    const containerRef = this.getContainerRef();
    if (containerRef && this.shadowRoot) {
      const itemsRefs = Array.from(
        containerRef.querySelectorAll('.shopify-buy__product'),
      );
      if (itemsRefs.length) {
        const currentRight = itemsRefs.find(
          current =>
            current.getBoundingClientRect().right >
            this.containerRight - OFFSET,
        );
        if (currentRight) this.currentRight = currentRight;
        const currentLeft = itemsRefs.find(
          current =>
            current.getBoundingClientRect().left >
            this.containerLeft + OFFSET - 10,
        );
        if (currentLeft) this.currentLeft = currentLeft;
      }
    }
  };

  disconnectedCallback() {
    window?.removeEventListener('resize', this.resizeListener);
    super.disconnectedCallback();
  }

  render() {
    return html`
      <div class="carousel-wrapper">
        <button
          title="Left"
          class="carousel-left"
          @click="${this.scrollCarouselLeft}"
        >
          ${chevronLeft}
        </button>
        <div id="${this.idx}"></div>
        <button
          title="Right"
          class="carousel-right"
          @click="${this.scrollCarouselRight}"
        >
          ${chevronRight}
        </button>
      </div>
    `;
  }
}
