const GET_CUSTOMER_ACCESS_TOKEN = `
mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
  customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;

const createAndFetchCustomerAccessToken = async ({ fetcher, multipassToken }) => {
  try {
    const {
      data: {
        customerAccessTokenCreateWithMultipass
      }
    } = await fetcher({
      query: GET_CUSTOMER_ACCESS_TOKEN,
      variables: {
        multipassToken
      }
    });
    return customerAccessTokenCreateWithMultipass.customerAccessToken.accessToken;
  } catch (error) {
    console.error('Error getting customer access from multipassToken', error);
  }
  return false;
};

export default createAndFetchCustomerAccessToken;