const CHECKOUT_CUSTOMER_ASSOCIATE = `
mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
  checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
    customer {
      id
    }
  }
}
`

const associateCustomerWithCheckout = async ({ fetcher, checkoutId, customerAccessToken }) => {
  try {
    const association = await fetcher({
      query: CHECKOUT_CUSTOMER_ASSOCIATE,
      variables: {
        checkoutId,
        customerAccessToken
      }
    });
    console.info('Successfully connected user to checkout!')
    return association;
  } catch (error) {
    console.error('Error associating user with checkout', error);
  }
  return false;
};

export default associateCustomerWithCheckout;